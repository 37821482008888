<template>
  <div class="login-container">
    <div class="find-form">
      <div class="f-header">找回密码</div>
      <el-form label-width="0px" :model="loginForm3" :rules="loginRules3" ref="loginForm3">
        <el-form-item prop="phone">
          <el-input v-model="loginForm3.phone" placeholder="请输入登录手机号"></el-input>
        </el-form-item>
        <el-form-item prop="code" class="password-item">
          <el-input v-model="loginForm3.code" placeholder="请输入短信验证码"></el-input>
          <div class="l-forget" v-show="showTimer">重新发送({{ time }})</div>
          <div class="l-forget" v-show="!showTimer" @click="getCode('forgetPwd', loginForm3.phone)">
            获取验证码
          </div>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input v-model="loginForm3.pwd" type="password" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item prop="rePwd">
          <el-input
            v-model="loginForm3.rePwd"
            type="password"
            placeholder="请再次输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="login-btn" type="primary" @click="onFindPwd('loginForm3')">
            确认
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5';
export default {
  name: '',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.loginForm3.Repwd !== '') {
          this.$refs.loginForm3.validateField('Repwd');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      console.log(value, 'rePwd');
      console.log(rule, 'rule');
      console.log(this.loginForm3.pwd, 'pwd');
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.loginForm3.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      time: 60,
      showTimer: false,
      branchId: this.$.data.get('defaultBranch').branchId,
      loginForm3: {
        phone: '',
        code: '',
        pwd: '',
        rePwd: ''
      },
      loginRules3: {
        phone: [
          { required: true, message: '请输入正确的手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确' }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ],
        pwd: [
          { validator: validatePass, trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '请输入6-20位密码，区分大小写，不能使用空格！',
            trigger: 'blur'
          }
        ],
        rePwd: [{ validator: validatePass2, trigger: 'blur' }]
      }
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    // 获取验证码
    getCode(type, phone) {
      if (phone == '') {
        this.$.ui.toast('请输入手机号');
      } else {
        this.showTimer = true;
        this.time = 60;
        this.get(
          '/biz/sms/checkCode',
          {
            mobile: phone,
            smsType: type
          },
          e => {
            if (e.code === 200) {
              const setI = setInterval(() => {
                if (this.time <= 0) {
                  clearInterval(setI);
                  this.showTimer = false;
                  return;
                }
                this.time -= 1;
              }, 1000);
            } else {
              this.$.ui.toast(e.msg);
              this.showTimer = false;
            }
          }
        );
      }
    },
    onFindPwd(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const newPwd = md5(this.loginForm3.pwd);
          this.post(
            '/biz/teacher/login/resetPwd',
            { checkCode: this.loginForm3.code, newPwd: newPwd, mobileNo: this.loginForm3.phone },
            e => {
              if (e.code == 200) {
                this.$.ui.toast('修改成功');
                setTimeout(() => {
                  window.localStorage.removeItem('Token');
                  this.user = {};
                  this.loginForm3 = {
                    phone: '',
                    code: '',
                    pwd: '',
                    rePwd: ''
                  };
                  this.href('/login');
                }, 1000);
              } else {
                this.$.ui.toast(e.msg);
              }
            }
          );
        } else {
        }
      });
    }
  },
  components: {}
};
</script>

<style></style>
